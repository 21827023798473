import { useScriptGoogleTagManager, useScriptGoogleAnalytics, useScript, defineNuxtPlugin } from '#imports'

export default defineNuxtPlugin({
  name: "scripts:init",
  env: { islands: false },
  parallel: true,
  setup() {
    const googleAnalytics = useScriptGoogleAnalytics({})
    const googleTagManager = useScriptGoogleTagManager({})
    return { provide: { $scripts: { googleAnalytics, googleTagManager } } }
  }
})