<template>
  <div class="relative isolate h-full w-full overflow-hidden bg-neutral-950">
    <div
      class="absolute inset-0 h-full w-full animate-pulse opacity-20"
      style="animation-duration: 5s"
    >
      <BrandTopography />
    </div>
    <div
      class="absolute inset-0 z-[1] h-full w-full bg-gradient-to-b from-transparent via-neutral-950/50 to-neutral-950"
    />
    <main
      class="relative z-[2] mx-auto flex h-svh w-full max-w-7xl flex-col items-center justify-center px-6 pt-10 pb-16 sm:pb-24 lg:px-8"
    >
      <NuxtImg
        provider="storyblok"
        class="mx-auto h-20 w-auto"
        height="80"
        width="92"
        preload
        priority
        loading="eager"
        src="https://a.storyblok.com/f/308362/1080x1080/a4d93ae358/live-for-today-logo-white.png"
        alt="Live For Today"
      />
      <UCard
        class="mx-auto mt-12 max-w-prose text-center"
        :ui="{
          root: 'bg-neutral-900/60 backdrop-blur-lg',
          header: 'p-2 sm:p-4',
          body: 'p-2 sm:p-4',
          footer: 'p-2 sm:p-4',
        }"
      >
        <template #header>
          <p class="text-base font-bold text-yellow-400 uppercase">
            Error {{ error.statusCode }}
          </p>
          <h1 class="text-2xl font-extrabold tracking-tight text-white">
            Oops! We encountered an error.
          </h1>
        </template>
        <p class="text-base font-medium text-neutral-400">
          {{ error.message }}
        </p>
        <template #footer>
          <UButton
            to="/"
            size="xl"
            variant="subtle"
            class="w-full justify-center text-center"
          >
            Back to home
          </UButton>
        </template>
      </UCard>
    </main>
  </div>
</template>

<script setup lang="ts">
useHead({
  htmlAttrs: { class: 'h-full' },
  bodyAttrs: { class: 'h-full bg-black' },
});

defineProps({
  error: {
    type: Object,
    default: () => ({}),
  },
});
</script>
