export default defineNuxtRouteMiddleware((to) => {
  // Remove trailing slash if present and not the root path
  if (to.path !== '/' && to.path.endsWith('/')) {
    const newPath = to.path.slice(0, -1);
    return navigateTo(newPath + to.fullPath.slice(to.path.length), {
      redirectCode: 301,
    });
  }

  // Redirect from www to non-www
  if (to.fullPath.includes('://www.')) {
    const nonWwwUrl = to.fullPath.replace('://www.', '://');
    return navigateTo(nonWwwUrl, { redirectCode: 301 });
  }

  // Define a map of redirects
  const redirects = {
    '/locations/ripley-castle': '/locations/ripley-castle-activity-centre',
    '/locations/hazlewood-castle':
      '/locations/hazlewood-castle-activity-centre',
    '/climbing': '/locations/harrogate-climbing-centre',
    '/locations/offsite-adventures': '/locations/yorkshire-adventures',
    '/confirmation/ripley-castle':
      '/booking/confirmation?location=ripley_castle_activity_centre',
    '/confirmation/hazlewood-castle':
      '/booking/confirmation?location=hazlewood_castle_activity_centre',
    '/confirmation/harrogate-climbing-centre':
      '/booking/confirmation?location=harrogate_climbing_centre',
    '/confirmation/offsite-adventures':
      '/booking/confirmation?location=yorkshire_adventures',
    '/ripley-castle-booking-confirmation':
      '/booking/confirmation?location=ripley_castle_activity_centre',
    '/hazlewood-castle-booking-confirmation':
      '/booking/confirmation?location=hazlewood_castle_activity_centre',
    '/harrogate-climbing-centre-booking-confirmation':
      '/booking/confirmation?location=harrogate_climbing_centre',
    '/offsite-adventures-booking-confirmation':
      '/booking/confirmation?location=yorkshire_adventures',
    '/kids': '/groups/kids',
    '/kids/holiday-sessions': '/groups/kids/holidays',
    '/kids/home-education': '/groups/kids/home-education',
    '/groups/kids-parties': '/groups/kids/parties',
    '/groups/corporates': '/groups/corporate-team-building',
    '/gift-vouchers': '/gifts',
    '/deals': '/offers',
    '/legal/cookies-policy': '/legal/policies?tab=cookies',
    '/legal/gdpr': '/legal/policies?tab=gdpr',
    '/legal/data-request': '/legal?tab=data_request',
    '/history': '/about?tab=history',
    '/review': '/customers/reviews',
    '/climbing/external-groups': '/external-instructors',
    '/climbing/first-visit': '/legal/safety-waivers?tab=climbing_centres',
    '/climbing/kids-classes': '/activities/kids-climbing-intro',
    '/climbing/adult-classes': '/activities/adults-climbing-intro',
    '/climbing/nicas': '/activities/nicas-classes',
    '/climbing/membership': '/activities/memberships-passes',
    '/climbing/socials': '/activities/social-clubs',
    '/feedback': '/customers/feedback',
    '/escape-rooms-harrogate': '/activities/escape-rooms',
    '/activity/save-ripley-escape-room-harrogate': '/activities/escape-rooms',
    '/activity/crack-the-enigma': '/activities/escape-rooms',
    '/ripley-castle-harrogate': '/locations/ripley-castle-activity-centre',
    '/hazlewood-castle-leeds': '/locations/hazlewood-castle-activity-centre',
    '/adventures': '/locations/yorkshire-adventures',
    '/activities/canoeing': '/activities/canoeing-kayaking',
    '/activities/kayaking': '/activities/canoeing-kayaking',
    '/activities/bushcraft-yorkshire': '/activities/bushcraft',
    '/activities/quad-biking-yorkshire': '/activities/quad-biking',
    '/bby': '/customers/lbs/bby',
    '/cvc': '/customers/lbs/cvc',
    '/dxc': '/customers/lbs/dxc',
    '/vxy': '/customers/lbs/vxy',
    '/xty': '/customers/fbotm/xty',
    '/vvm': '/customers/fbotm/vvm',
    '/pts': '/customers/fbotm/pts',
    '/dnm': '/customers/fbotm/dnm',
    '/hux': '/customers/fbotm/hux',
    '/yup': '/customers/fbotm/yup',
    '/ewq': '/customers/fbotm/ewq',
    '/fgh': '/customers/fbotm/fgh',
    '/qws': '/customers/fbotm/qws',
    '/dis': '/customers/fbotm/dis',
    '/asd': '/customers/fbotm/asd',
    '/bcv': '/customers/fbotm/bcv',
    '/tuv': '/customers/fbotm/tuv',
    '/plk': '/customers/fbotm/plk',
    '/mbe': '/customers/fbotm/mbe',
    '/yed': '/customers/fbotm/yed',
    '/zvc': '/customers/fbotm/zvc',
    '/kit': '/customers/fbotm/kit',
    '/groups/couples': '/groups',
    '/corporate': '/corporates',
    '/stag-activities-harrogate': '/groups/stags',
    '/kids-parties-harrogate': '/groups/kids/parties',
    '/activities/air-rifle-range-yorkshire': '/activities/air-rifle-shooting',
    '/aboutus': '/about',
    '/contact-us/1000': '/contact',
    '/redeem': '/gifts/redeem',
    '/activities/nicas': '/activities/nicas-classes',
    '/team-building-activities-leeds': '/corporates',
    '/activities/last-bomb-standing': '/activities/outdoor-escape-games',
    '/activities/defuse-the-bomb': '/activities/outdoor-escape-games',
    '/education': '/groups/schools',
    '/climbing/classes/social-clubs': '/activities/social-clubs',
    '/activity/quad-double': '/activities/quad-biking',
    '/activities/yorkshire-games': '/activities/old-school-sports-day',
    '/activity/crossbow-leeds': '/activities/crossbows',
    '/activity/crossbows-leeds': '/activities/crossbows',
    '/groups/families': '/groups/kids',
    '/latest-offers': '/offers',
    '/activities/development-classes': '/activities/beginners-course',
    '/activities/climbing-taster': '/activities/adults-climbing-intro',
    '/activities/lft-games': '/activities/old-school-sports-day',
    '/climbing/classes/kids': '/activities/kids-climbing-intro',
    '/activities/beginner-course': '/activities/beginners-course',
    '/job-opportunities': '/jobs',
    '/activities/indoor-climbing': '/locations/harrogate-climbing-centre',
    '/team-building-activities': '/corporates',
    '/activity/caving-yorkshire-dales': '/activities/caving',
    '/adult-celebrations-leeds': '/groups/celebrations',
    '/activity/air-rifle-range-yorkshire': '/activities/air-rifle-shooting',
    '/stag-do-activities': '/groups/stags',
    '/activity/paddle-boarding': '/activities/paddleboarding',
    '/adult-celebrations': '/groups/celebrations',
    '/climbing/new-climber': '/activities/adults-climbing-intro',
    '/activities/kids-classes': '/activities/nicas-classes',
    '/climbing/classes': '/activities/beginners-course',
    '/activity/caving-yorkshire': '/activities/caving',
    '/activity/high-ropes': '/activities/high-low-ropes',
    '/activity/extreme-caving-yorkshire': '/activities/caving',
    '/activity/yorkshire-caving': '/activities/caving',
    '/climbing/kids': '/activities/nicas-classes',
    '/kidsparty': '/group/kids/parties',
    '/kids-parties': '/group/kids/parties',
    '/kids-activities': '/group/kids',
    '/team-building-activities-harrogate': '/corporates',
    '/hen-activities-harrogate': '/groups/hens',
    '/hen-party-ideas': '/groups/hens',
    '/activity/rock-climbing/yorkshire': '/activities/rock-climbing',
    '/activity/learn-to-lead': '/activities/learn-to-lead-course',
    '/activity/inside-to-out': '/activities/rock-climbing',
    '/stag-activities-york': '/groups/stags',
    '/adult-celebrations-york': '/groups/celebrations',
    '/activities/outdoor-climbing': '/activities/rock-climbing',
    '/activity/rock-climbing-yorkshire': '/activities/rock-climbing',
    '/team-building': '/corporates',
    '/activities/caving-in-yorkshire': '/activities/caving',
    '/activities/rockclimbingyorkshire-brimhamrocksclimbing':
      '/activities/rock-climbing',
    '/packages': '/activities',
  };

  // Check for direct path matches
  if (redirects[to.path]) {
    return navigateTo(redirects[to.path], { redirectCode: 301 });
  }

  // Handle pattern matches
  if (
    to.path !== '/gifts' &&
    to.path !== '/gifts/redeem' &&
    to.path.startsWith('/gifts/')
  ) {
    return navigateTo('/gifts', { redirectCode: 301 });
  }

  if (to.path.startsWith('/offers/')) {
    return navigateTo('/offers', { redirectCode: 301 });
  }

  // Handle /activity/* to /activities/* redirect
  if (to.path.startsWith('/activity/')) {
    const newPath = '/activities' + to.path.substring('/activity'.length);
    return navigateTo(newPath, { redirectCode: 301 });
  }
});
