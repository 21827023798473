export default defineAppConfig({
  ui: {
    colors: {
      primary: 'yellow',
      neutral: 'neutral',
    },
    button: {
      slots: {
        base: ['font-extrabold'],
      },
    },
  },
});
