import { defineNuxtPlugin } from '#app/nuxt'
import { LazyActivity, LazyBlog, LazyCTABook, LazyCTAWizard, LazyChangelog, LazyContentContactDetails, LazyContentFeature, LazyContentForm, LazyContentImage, LazyContentLinks, LazyContentLinksDownload, LazyContentLinksText, LazyContentMediaGrid, LazyContentProse, LazyContentTimeline, LazyContentTopActivities, LazyContentVideo, LazyEvent, LazyGroup, LazyLayoutColumn, LazyLayoutColumns, LazyLayoutTabs, LazyListActivities, LazyListBlog, LazyListChangelog, LazyListEvents, LazyListGifts, LazyListLocations, LazyListOffers, LazyListPress, LazyLocation, LazyPackage, LazyPage, LazyPress, LazyWidgetBambooHR, LazyWidgetGoogleMeet, LazyWizard, LazyIcon } from '#components'
const lazyGlobalComponents = [
  ["Activity", LazyActivity],
["Blog", LazyBlog],
["CTABook", LazyCTABook],
["CTAWizard", LazyCTAWizard],
["Changelog", LazyChangelog],
["ContentContactDetails", LazyContentContactDetails],
["ContentFeature", LazyContentFeature],
["ContentForm", LazyContentForm],
["ContentImage", LazyContentImage],
["ContentLinks", LazyContentLinks],
["ContentLinksDownload", LazyContentLinksDownload],
["ContentLinksText", LazyContentLinksText],
["ContentMediaGrid", LazyContentMediaGrid],
["ContentProse", LazyContentProse],
["ContentTimeline", LazyContentTimeline],
["ContentTopActivities", LazyContentTopActivities],
["ContentVideo", LazyContentVideo],
["Event", LazyEvent],
["Group", LazyGroup],
["LayoutColumn", LazyLayoutColumn],
["LayoutColumns", LazyLayoutColumns],
["LayoutTabs", LazyLayoutTabs],
["ListActivities", LazyListActivities],
["ListBlog", LazyListBlog],
["ListChangelog", LazyListChangelog],
["ListEvents", LazyListEvents],
["ListGifts", LazyListGifts],
["ListLocations", LazyListLocations],
["ListOffers", LazyListOffers],
["ListPress", LazyListPress],
["Location", LazyLocation],
["Package", LazyPackage],
["Page", LazyPage],
["Press", LazyPress],
["WidgetBambooHR", LazyWidgetBambooHR],
["WidgetGoogleMeet", LazyWidgetGoogleMeet],
["Wizard", LazyWizard],
["Icon", LazyIcon],
  
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})
