<template>
  <UApp>
    <NuxtLoadingIndicator color="orange" :height="4" />
    <NuxtLayout>
      <NuxtPage />
    </NuxtLayout>
  </UApp>
</template>

<script setup lang="ts">
import { agreedToCookiesScriptConsent } from '#imports';

useScript('https://www.google-analytics.com/analytics.js', {
  trigger: agreedToCookiesScriptConsent,
});
</script>

<style>
@import 'tailwindcss';
@import '@nuxt/ui';

:root {
  --ui-radius: var(--radius-lg);
}

html {
  @apply scroll-smooth;
}

body {
  @apply bg-neutral-950 antialiased;
}

@plugin "@tailwindcss/typography";

@theme {
  --font-sans: 'Montserrat', sans-serif;
  --font-family-sans: 'Montserrat', sans-serif;

  --font-mono: 'Fira Code', monospace;
  --font-family-mono: 'Fira Code', monospace;

  --color-yellow-400: #ffcd16;
}
</style>
